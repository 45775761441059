import React, { useState } from "react";
import push_pin from "../../assets/images/push_pin.svg";
import { setPinnedSearches, setToaster } from "../../redux/actions";
import { addPinDocument, deletePinDocument } from "../apiCall";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { getSasUrl } from "../apiCall";
const HeaderComponent = (props) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [pinnedDocs, setPinnedDocs] = useState(props.pinnedSearches);
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(false);
  const [loader, setLoader] = useState(false);

  function removeQueryString(url) {
    // Check if the URL contains a "?" character
    if (url.includes("?")) {
      // Split the URL based on "?"
      var parts = url.split("?");

      // Take the part before "?"
      var newUrl = parts[0];

      // You can also add back the "?" if needed
      // var newUrl = parts[0] + '?';
      return newUrl;
    }
    // If there is no "?" in the URL, return the original URL
    return url;
  }

  const redirectToPdf = (e, url) => {
    if (url !== "NO LINK") {
      e.preventDefault();
      setLoader(true);
      getSasUrl({
        url: removeQueryString(url),
        directory: props.docDetail.result.root.docs[0].directory
          ? props.docDetail.result.root.docs[0].directory
          : "",
      })
        .then((res) => {
          setLoader(false);
          !props.dualDoc && window.open(res.data.convertedUrl, "_blank");
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  //function for adding a pindoc
  const pinTheDoc = (result) => {
    setApiCallInProgress(true);
    let pindoc = {
      documentId: result.id,
      searchResults: result,
    };

    addPinDocument(pindoc)
      .then((data) => {
        setPinnedDocs((allReadyPinnedDoc) => [
          ...allReadyPinnedDoc,
          data.data.data.pindocumentId,
        ]);
        // props.setPinnedSearches(
        //   [...pinnedDocs, data.data.data.pindocumentId].reverse()
        // );
        setApiCallInProgress(false);
      })
      .catch(() => {
        setToaster(true);
      });
  };

  //function for unpinning document, delete from database the document is pinned
  const unpindoc = (pinId) => {
    setApiCallInProgress(true);
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.pinId !== pinId)
        );

        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.pinId !== pinId).reverse()
        );
        setApiCallInProgress(false);
      })
      .catch(() => {
        setToaster(true);
      });
  };
  const pinButtonClick = (result) => {
    if (pinnedDocs.length < 10) {
      pinTheDoc(result);
      setDisableButton1(true);
      setDisableButton2(false);
    } else {
      alert(
        "You have reached the maximum limit of pinned documents. Please unpin some documents before pinning new ones"
      );
    }
  };
  const unPinButtonClick = (pinId) => {
    setDisableButton1(false);
    setDisableButton2(true);
    unpindoc(pinId);
  };
  const getType = (type) => {
    if (type === "SETUP INSTRUCTION") {
      return "Setup Instruction";
    } else if (type === "OPERATING PROCEDURES") {
      return "Operating Procedure";
    }
    if (type === "setupInstruction") {
      return "Setup Instruction";
    } else if (type === "operatingProcedure") {
      return "Operating Procedure";
    } else if (type === "PROMOTIONAL MATERIAL") {
      return "Promotional Material";
    } else if (type === "TECHNICAL NOTE") {
      return "Techical Note";
    } else {
      return type;
    }
  };
  return (
    <div>
      <div className="mt-[-1%] p-5 bg-white border border-gray-300 rounded-md">
        <div className="flex ">
          <div>
            <div className="flex mb-4">
              <div className="xauth-tag">
                <a
                  class={`ui ${
                    props?.result.documentType ? "blue" : "orange"
                  } ribbon label`}
                >
                  {props?.result.documentType ? "authored" : "ingested"}
                </a>
              </div>
              <div className="font-semibold mt-1">{props.title}</div>
            </div>
            <div className="flex">
              <div className=" p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-sm">
                Category:{props.category}
              </div>
              <div className="ml-3 p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-sm">
                Knowledge Source:{getType(props.type)}
              </div>
            </div>
            <div className="mt-3 p-1 pl-4 pr-4 rounded-3xl bg-blue-100 w-fit border-2 text-[#0971CE] border-[#0971CE] text-sm flex align-middle">
              <span className="material-symbols-outlined text-[20px]">
                link
              </span>
              <span className="ml-3">
                Original file:
                {loader ? (
                  <Loader size="small" active inline />
                ) : (
                  <a
                    className={
                      props.fileLink === "NO LINK" || props.dualDoc
                        ? "cursor-text"
                        : `underline text-[10px] md:text-[12px]`
                    }
                    href="/searchResult"
                    onClick={(e) => redirectToPdf(e, props.fileLink)}
                  >
                    {props.dualDoc ? "NO FILE AVAILABLE" : props.fileName}
                  </a>
                )}
              </span>
            </div>
          </div>

          {pinnedDocs?.find((item) => item.documentId === props.result.id) ? (
            <div className="pinImage mt-[-1.4rem]">
              <button
                className="ml-auto w-8 h-8"
                disabled={disableButton2 || apiCallInProgress}
                onClick={() =>
                  unPinButtonClick(
                    pinnedDocs?.find(
                      (item) => item.documentId === props.result.id
                    ).pinId
                  )
                }
              >
                <img
                  className="h-7 m-[2px] rounded-full bg-[#1f97dc] absolute right-[1em] md:right-[2em]"
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton2 && (
                <div className="header-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          ) : (
            <div className="pinImage mt-[-1.4rem]">
              <button
                className="ml-auto w-8 h-8  unpindoc"
                disabled={disableButton1 || apiCallInProgress}
                onClick={() => pinButtonClick(props.result)}
              >
                <img
                  className="h-7 m-[2px]  rotate-45 rounded-full bg-gray-200 absolute right-[1em] md:right-[2em] "
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton1 && (
                <div className="header-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps, {
  setToaster,
  setPinnedSearches,
})(HeaderComponent);
