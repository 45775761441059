import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
  const tracking_id = 'G-MV82KJXTG0'; 
  ReactGA4.initialize(tracking_id);
  console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (
  category,
  action,
) => {
  console.log("GA event = ", "category :" , category, ":", "action :", action);

  ReactGA4.event({
    category: category,
    action: action,
  });
};

const previewPageGA = (path) => {
    // ReactGA4.previewPageGA(path);
    ReactGA4.send({ hitType: "pageview", page: path });
}

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, previewPageGA };