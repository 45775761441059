import React from "react";
import {
  storeOutline,
  storeFileLink,
  storeDocDetail,
} from "../../redux/actions";
import { Card } from "semantic-ui-react";
import { useNavigate } from "react-router";
import push_pin from "../../assets/images/push_pin.svg";
import pdfimage from "../../assets/images/download.jpg";
import { connect } from "react-redux";

const PinnedDocs = (props) => {
  const navigate = new useNavigate();
  const handleView = (data) => {
    data?.procedureTitle
      ? toViewAuthDoc(data)
      : toViewDoc(
          data,
          data.id,
          data.root.docs[0].id,
          data.root.docs[0].title,
          data.root.docs[0].file_link,
          data.root.docs[0].outline,
          data.root.docs[0].equipment[0],
          data.root.docs[0].type,
          data.root.docs[0].file_name,
          data.meta
        );
  };
  const toViewAuthDoc = (result) => {
    props.storeDocDetail({
      authorData: JSON.stringify(result),
      authdoc: true,
    });
    navigate("/searchResultDetail");
  };
  const toViewDoc = (
    data,
    id,
    rootId,
    title,
    file_link,
    outline,
    equipment,
    type,
    fileName,
    meta
  ) => {
    props.storeOutline(JSON.stringify(outline));
    props.storeFileLink(file_link);
    props.storeDocDetail({
      result: data,
      id: id,
      rootId: rootId,
      title: title,
      file_link: file_link,
      equipment: equipment,
      type: type,
      fileName: fileName,
      meta: meta,
    });
    navigate("/searchResultDetail");
  };
  const slicingFunction = (text) => {
    if (text?.length > 35) {
      return text.slice(0, 35) + "...";
    } else {
      return text;
    }
  };
  const shortenName = (name) => {
    if (name === "setupInstruction") {
      return "Setup Instruction";
    } else if (name === "operatingProcedure") {
      return "Operating Procedure";
    }
    if (name?.length > 25) {
      return name.substring(0, 25) + "...";
    }

    return name;
  };

  return (
    <Card key={Math.random()} className=" cardtot">
      <Card.Content className="cardheader">
        <div
          className="titleofcard"
          onClick={() => {
            handleView(props.data.searchResults);
          }}
        >
          {props?.data?.searchResults?.procedureTitle
            ? slicingFunction(props?.data?.searchResults?.procedureTitle[0])
            : slicingFunction(props?.data?.searchResults?.root?.docs[0]?.title)}
        </div>

        <div className="pinImage">
          <button
            className="w-8 h-8  rounded-full "
            onClick={() => {
              props.handlepopupOn && props.handlepopupOn(props.data);
            }}
          >
            <img
              className={`h-7 m-[2px] rounded-full bg-[#1f97dc] absolute top-[1em] right-[1em] ${
                props.handlepopupOn ? "cursor-pointer" : "cursor-default"
              }  `}
              src={push_pin}
              alt="push pin logo"
            />
          </button>
        </div>
      </Card.Content>

      <Card.Content extra className="pdfimagewrapper">
        <div className="pdfimage">
          {props.data?.searchResults?.documentType ? (
            <div className="tag-icon xauth">
              <a className="xauth-icon">Xauth</a>
            </div>
          ) : (
            <div className="tag-icon ing">
              <a className="xauth-icon">Ing</a>
            </div>
          )}
        </div>
        <div className="right-div">
          <div className="filetype">
            {props.data?.searchResults?.documentType
              ? "Xauthor File"
              : "Original File"}
          </div>
          <div className="linktoredirect">
            {shortenName(
              props.data?.searchResults?.documentType
                ? props.data?.searchResults?.documentType
                : props.data?.searchResults?.root?.docs[0]?.file_name
            )}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  storeDocDetail,
  storeFileLink,
  storeOutline,
})(PinnedDocs);
