import React, { useState, useRef } from "react";
import { Modal, Icon } from "semantic-ui-react";
import { raiseAFeedbackApi } from "../apiCall";
const RaiseAFeedback = (props) => {
  const [form, setForm] = useState({ summary: "", description: "", query: "" });
  const [enableFeedbackForm, setEnableFeedbackForm] = useState(false);
  const [fileDetails, setFileDetails] = useState([]);
  const inputFileType = ["mov", "mp4", "jpg", "jpeg", "png"];
  const fileRef = useRef();
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmitFeedback = async (e) => {
    e.preventDefault();

    if (form.summary !== "" || form.query !== "") {
      //setEnableFeedbackForm((enableFeedbackForm) => !enableFeedbackForm);
      await raiseAFeedbackApi(form)
        .then((res) => {
          setEnableFeedbackForm((enableFeedbackForm) => !enableFeedbackForm);
        })
        .catch((err) => {
          alert("Feedback submission failed ");
        });
    } else {
      alert("Enter all mandatory fields ");
    }
  };
  const showFeedbackForm = () => {
    setEnableFeedbackForm((enableFeedbackForm) => !enableFeedbackForm);
  };
  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
          fileSize +
          ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      return false;
    } else if (!inputFileType.includes(fileType)) {
      alert(
        "We do not accept files of this media type. Only file with png, jpg, jpeg, mp4 and mov are allowed."
      );
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );

      if (isFileExist) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        return false;
      }
    }
    return true;
  };
  const renderUploadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // fileRef.current.click();
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    const fileData = e.target.files;
    for (let i = 0; i < fileData.length; i++) {
      if (validateFile(fileData[i])) {
        if (!fileData[i].name) {
          const binary = atob(fileData[i].split(",")[1]);
          const array = [];
          var file;
          for (let i = 0; i < binary.length; i += 1) {
            array.push(binary.charCodeAt(i));
          }
          file = new Blob([new Uint8Array(array)], {
            type: "image/png",
          });
          file.name = `${new Date()}.png`;
          file.lastModifiedDate = new Date();
          const myFile = new File([file], file.name, {
            type: file.type,
          });
          setFileDetails((fileDetails) => [...fileDetails, myFile]);
        }
        const fileChild = document.getElementById("files");
        const url = window.URL.createObjectURL(new Blob([fileData[i]]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png");
        fileChild.appendChild(link);
        setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
      }
    }
  };
  return (
    <>
      <button
        onClick={() => showFeedbackForm()}
        id="filterButton"
        className={`flex p-1 w-50 h-10 mr-1 ml-auto pt-2 text-[#0971CE] bg-white
             bg-[#0971CE] border-2 rounded-md`}
      >
        Raise a Feedback
      </button>

      {enableFeedbackForm && (
        <Modal open={enableFeedbackForm} className="raiseAFeedback" size="tiny">
          <div className="w-[50em] mx-auto p-6 bg-white rounded-lg ">
            <h2 className="text-3xl text-[#0971CE] font-bold ">
              Raise a Feedback
            </h2>

            <form className="bg-white p-8 rounded-lg ml-[-22px] ">
              <div className="mb-4">
                <label
                  for="summary"
                  className="block text-gray-700 text-sm font-bold"
                >
                  Summary*
                </label>
                <textarea
                  type="text"
                  name="summary"
                  id="summary"
                  required
                  onChange={handleChange}
                  className="w-full h-[7em] border border-[#0971CE] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-#0971CE focus:border-#0971CE"
                />
              </div>
              <div className="mb-4">
                <label
                  for="description"
                  className="block text-gray-700 text-sm font-bold"
                >
                  Description*
                </label>
                <textarea
                  name="description"
                  id="description"
                  onChange={handleChange}
                  className="w-full h-[10em] border border-[#0971CE] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-#0971CE focus:border-#0971CE"
                ></textarea>
              </div>
              <div className="mb-4">
                <label
                  for="query"
                  className="block text-gray-700 text-sm font-bold"
                >
                  Search Query*
                </label>
                <textarea
                  type="text"
                  name="query"
                  id="query"
                  required
                  onChange={handleChange}
                  className="w-full h-[5em] border border-[#0971CE] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-#0971CE focus:border-#0971CE"
                />
              </div>
              <div className="mb-4">
                <label
                  for="query"
                  className="block text-gray-700 text-sm font-bold"
                >
                  Add Image
                </label>
                <Icon
                  name="attach"
                  id="attach"
                  className="dropdown-icon"
                  onClick={renderUploadFile}
                />
                <input
                  type="file"
                  id="video"
                  className="takefile"
                  name="media"
                  multiple
                  accept="image/png, image/jpeg, image/jpg, video/mp4, image/*, video/*, HEVC/H.265, HEVC/H.264, JPEG/H.264, MOV, mov"
                  ref={fileRef}
                  onChange={handleInputChange}
                />
              </div>
              <button
                onClick={(e) => handleSubmitFeedback(e)}
                className="bg-[#0971CE] text-white rounded-full px-4 py-2 hover:bg-blue-700"
              >
                Submit
              </button>
              <button
                onClick={(e) => {
                  showFeedbackForm();
                }}
                className="bg-white text-blue-500 rounded-full border border-blue-500 px-4 py-2 ml-2 hover:bg-blue-100"
              >
                Cancel
              </button>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RaiseAFeedback;
